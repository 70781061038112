/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import propTypes, { string } from 'prop-types';
import { bpProps } from 'utils/responsiveUtils';
import { connect } from 'react-redux';
import get from 'lodash/get';
import FanValidation from 'components/FanValidation';
import tracker from 'utils/tracking';
import { Icon } from '@springforcreators/propel-ui';
import ListingGalleryMenu from './ListingGalleryMenu';
import './ListingGallery.scss';

function ListingGallery(props) {
  const {
    bpIsGT,
    getStyles,
    listing,
    imageSet,
    productName,
    initialImageIndex
  } = props;

  const viewportEl = useRef(null);
  const featuredImgEl = useRef(null);
  const startingIndex = initialImageIndex || 0;
  const [activeIndex, setActiveIndex] = useState(startingIndex);
  const activeImage = imageSet[activeIndex] || imageSet[0];
  const [loadingTimestamp, setLoadingTimestamp] = useState(null);
  const [zoomActive, setZoomActive] = useState(false);
  const [arrowActive, setArrowActive] = useState(false);

  useEffect(() => {
    const { current } = viewportEl;
    const eventListener = (event) => {
      if (bpIsGT('tabletSm')) {
        const { offsetX, offsetY } = event;
        current.style.backgroundPositionX = `${offsetX * -1}px`;
        current.style.backgroundPositionY = `${offsetY * -1}px`;
      }
    };

    current.addEventListener('mousemove', eventListener);
    return () => current.removeEventListener('mousemove', eventListener);
  }, [viewportEl, bpIsGT]);

  useEffect(() => {
    if (!featuredImgEl?.current?.complete || !activeImage) {
      setLoadingTimestamp(new Date().getTime());
    }
  }, [activeImage]);

  const disableIsLoading = () => {
    if (new Date().getTime() - loadingTimestamp > 500) {
      setLoadingTimestamp(null);
    } else {
      // If load time is less that 500 ms, for better user experience, we
      // fake a load time of at least 500ms This is to prevent the loader
      // from flashing on the screen for only 1 frame before disappearing
      setTimeout(() => setLoadingTimestamp(null), 500);
    }
  };

  const viewNextImage = () => {
    const nextIndex = activeIndex >= imageSet.length - 1 ? 0 : activeIndex + 1;
    const nextImage = imageSet[nextIndex];
    if (nextImage) {
      setActiveIndex(nextIndex);
    }
  };

  return (
    <div className="listing-gallery">
      {((!bpIsGT('mobileLg') && imageSet.length > 1) || bpIsGT('mobileLg'))
      && (
        <ListingGalleryMenu
          thumbImages={ imageSet }
          activeIndex={ activeIndex }
          onThumbClick={ (thumbData) => {
            setActiveIndex(thumbData.index);
            tracker.track('product_page.thumbnail.clicked', { index: thumbData.index, src: thumbData.originalData.src });
          } }
        />
      )
      }
      <div
        ref={ viewportEl }
        className={ `listing-gallery-viewport ${zoomActive && !arrowActive ? 'zoom-active' : ''}` }
        onMouseEnter={ () => setZoomActive(true) }
        onMouseLeave={ () => setZoomActive(false) }
        style={ { backgroundImage: bpIsGT('tabletSm') && zoomActive && `url(${get(activeImage, 'full')})` } }
      >
        <img
          ref={ featuredImgEl }
          onLoad={ disableIsLoading }
          srcSet={ `${get(activeImage, 'small')} 500w, ${get(activeImage, 'src')} 800w` }
          src={ get(activeImage, 'src') }
          alt={ get(activeImage, 'side') ? get(activeImage, 'side') : `${productName} view-${activeIndex + 1}` }
          style={ { backgroundColor: getStyles('listing.bgStyles.backgroundColor') || '#FFFFFF' } }
        />
        { imageSet && imageSet.length > 1 && (
          <div
            className="listing-gallery__next-img"
            onClick={ viewNextImage }
            onMouseEnter={ () => setArrowActive(true) }
            onMouseLeave={ () => setArrowActive(false) }
            role="button"
          >
            <Icon name="ChevronRight" />
          </div>
        )}
      </div>

      <FanValidation orders={ listing?.orders } />
    </div>
  );
}

const {
  arrayOf,
  number,
  shape,
  func
} = propTypes;

ListingGallery.defaultProps = {
  imageSet: [],
  initialImageIndex: 0
};

ListingGallery.propTypes = {
  imageSet: arrayOf(shape({})),
  initialImageIndex: number,
  bpIsGT: func.isRequired,
  getStyles: func.isRequired,
  productName: string.isRequired,
  listing: shape({
    orders: arrayOf(shape({}))
  }).isRequired
};

const mapStateToProps = state => ({
  ...bpProps(state)
});

export default connect(mapStateToProps)(ListingGallery);
